import React, { useDebugValue, useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CardContent from '@mui/material/CardContent';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function AnnotatorList({ rows, setAnnotators, defaultCheck }) {
  const [annotatorData, setAnnotatorData] = useState(rows.annotatorsList);
  const [checked, setChecked] = React.useState([]);
  const [developerHashs, setDeveloperHashs] = useState([]);

  useEffect(() => {
    setAnnotatorData(rows.annotatorsList)
    var hashs = rows.annotatorsList.map(function (item) {
      return item['developerHash'];
    });
    setDeveloperHashs(hashs)
    if(defaultCheck){
      console.log('defaultchek', defaultCheck)
      setChecked(hashs);
      setAnnotators(hashs);
    }
  }, [rows])

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    setAnnotators(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
      setAnnotators(not(checked, items));
    } else {
      setChecked(union(checked, items));
      setAnnotators(union(checked, items));
    }
  };

  return (

    <Card>
      <CardHeader
        avatar={
          <Checkbox
            onClick={handleToggleAll(developerHashs)}
            checked={numberOfChecked(developerHashs) === developerHashs.length && developerHashs.length !== 0}
            indeterminate={
              numberOfChecked(developerHashs) !== developerHashs.length && numberOfChecked(developerHashs) !== 0
            }
            disabled={annotatorData.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={<Typography className="div">Annotators</Typography>}
        subheader={`${numberOfChecked(developerHashs)}/${developerHashs.length} selected`}
        style={{
          paddingLeft: 5,
          paddingTop: 3,
          paddingBottom: 3,
      }}
      />
      <Divider />
      <List
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
        disablePadding
      >
        {annotatorData.map((value) => {
          const labelId = value.developerHash;
          const randomInt = Math.floor(Math.random() * 30);
          const random = Math.floor(Math.random() * randomInt)

            return (
            <ListItem
              key={value.developerHash}
              role="listitem"
              disablePadding
            >

              <ListItemButton role={undefined} onClick={handleToggle(value.developerHash)} dense>
                  <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label={<p>{value.developerName} <b>({randomInt}/{random})</b></p>}

                  />

              </ListItemButton>

            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>

  );
}
