import {useState} from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';

const options = [
    'Project',
    'Company',
];

export  default  function MenuButton({onChange}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const open = Boolean(anchorEl);
    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        onChange({
            companyUsage: index + 1,
        })
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <List
                component="nav"
                aria-label="Filter setting"
                sx={{
                    color: 'text.secondary',
                    mx: 1,
                }}
            >
                <ListItem
                    button
                    aria-haspopup="listbox"
                    aria-controls="filterBy-menu"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClickListItem}
                >
                    <ListItemText
                        primary={
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '> *': {
                                  mx: 0.2,
                                },
                            }}>
                                <span>
                                    Filter by
                                </span>
                                <span>
                                    <b>{options[selectedIndex]}</b>
                                </span>

                                    <KeyboardArrowDownIcon/>
                            </Box>
                        }
                    />
                </ListItem>
            </List>
            <Menu
                id="filterBy-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                }}
            >
                {options.map((option, index) => (
                    <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                    >
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );

}
