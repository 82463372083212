import React, { useRef, useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoopIcon from '@mui/icons-material/CopyAllRounded';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider'
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { DeleteOutline } from '@mui/icons-material';
import { Badge, CardHeader } from '@mui/material';

export default function CvatUserInfo({ open, onClose, anchorEl, cvatUser }) {
  const id = open ? 'simple-popover' : undefined;
  const [emailCopy, setEmailCopy] = useState('');
  const [passwordCopy, setPasswordCopy] = useState('');

  const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
      •
    </Box>
  );

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >



      <Card sx={{ minWidth: 275 }} variant="outlined">
        <CardHeader title={<Typography variant="h6"><b>CVAT USER CREDENTIALS</b></Typography>}>
        </CardHeader>
        <Divider></Divider>
        <CardContent>
          <Stack
            direction='row'
            spacing={4}
            justifyContent="space-between"
            alignItems="center">
            <Typography sx={{ p: 2 }} ><b>USER ID</b>: {cvatUser.username}</Typography>
            <Tooltip title={emailCopy == null ? "" : emailCopy}  >
              <LoopIcon onClick={() => {
                setEmailCopy('Copied');
                setPasswordCopy('');
                navigator.clipboard.writeText(cvatUser.username)
              }} sx={{ cursor: 'pointer', color:'primary.main' }} />
            </Tooltip>
          </Stack>

          <Stack
            direction='row'
            spacing={2}
            justifyContent="space-between"
            alignItems="center">
            <Typography sx={{ p: 2 }}><b>PASSWORD</b>: {cvatUser.password1}</Typography>
            <Tooltip title={passwordCopy == null ? "" : passwordCopy} >
              <LoopIcon onClick={() => {
                setEmailCopy('');
                setPasswordCopy('Copied');
                navigator.clipboard.writeText(cvatUser.password1)
              }} sx={{ cursor: 'pointer', color:'primary.main'}} />
            </Tooltip>
          </Stack>
          <Stack
            direction='row'
            spacing={2}
            justifyContent="space-between"
            alignItems="center">
            <Typography sx={{ p: 2}}><b>REMAINING TASKS</b>:</Typography>
            <Typography sx={{ pl: .5,pr: .5,borderRadius: '50px', bgcolor: 'error.main',color:'white' }}> {cvatUser.pendingTasks}</Typography>
          </Stack>

        </CardContent>

      </Card>


    </Popover>
  );
}
