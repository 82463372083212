import http from "../http-common";

export const getQaData = (params) => {
    return http.get("/qa", { params });
};

export const getQaIannData = (params) => {
    return http.get("/qa/iann", { params });
};

export const getFeedbackData = (params) => {
    return http.get("/detect/feedback", {params})
}

export const getActivePlacements = (params) => {
    return http.get("library/placements/available", {params})
}
export const reviewArchive = (iannHash) => {
    return http.post(`/qa/iann/${iannHash}/review`)
}
export const getActiveBrands = (params) => {
    return http.get("/brands/active", {params})
}

export const getAnnotators = (params) => {
    return http.get("/qa/annotators", {params})
}
export const getAnnotatorsIann = (params) => {
    return http.get("/qa/iann/annotators", {params})
}

export const putAssignAnnotators = (media, params) => {
    return http.put(`/qa/media/${media}/developers`, null, { params })
}
export const putAssignAnnotatorsIann = (iannHash, developerHash, params) => {
    return http.put(`/qa/iann/${iannHash}/developers/${developerHash}`, null, { params })
}
export const postPopulate = (iannHash, limit) => {
    return http.post(`/qa/iann/${iannHash}/populate?limit=${limit}`)
}

export const postPropagation = (iannHash) => {
    return http.post(`/qa/iann/${iannHash}/propagate`)
}

export const postRelease = (iannHash) => {
    return http.post(`/qa/iann/${iannHash}/release`)
}

export const postHold = (iannHash) => {
    return http.post(`/qa/iann/${iannHash}/hold`)
}

export const postRevert = (iannHash) => {
    return http.put(`/qa/iann/${iannHash}/revert`)
}

export const postComplete = (iannHash) => {
    return http.post(`/qa/iann/${iannHash}/complete`)
}

export const deleteArchive = (iannHash) => {
    return http.delete(`/qa/iann/${iannHash}/archive`)
}

export const putDetectFeedbackApprove = (requestHash, params) => {
    return http.put(`/detect/feedback/${requestHash}`, null, { params })
}

export const putCvatTaskComplete = (taskHash, params) => {
    return http.put(`/qa/tasks/${taskHash}`, null, { params })
}

export const getMediaDetails = (taskHash, params) => {
    return http.get(`/qa/iann/${taskHash}`)
}

export const getMediaLogs = (taskHash, params) => {

    return http.get(`/qa/iann/${taskHash}/logs`)
}

export const getPlacementMediaDetection = (mediaDetectionId) => {
    return http.get(`/library/placements/mediaDetections/${mediaDetectionId}/draft`)
}

export const getDetectionSettingData = (detectionSetting) => {
    return http.get(`/library/placements/examples/info?detectionSettingId=${detectionSetting}`)
}

export const PostPlacementExample = (urlString) => {
    return http.post(`/library/placements/examples`,
        urlString,
        {
            headers: {
                "Content-Type": undefined
            }
        })
}

export const UnassignTask = (taskHash) => {
    return http.put(`/qa/iann/${taskHash}/developers/unassign`)
}
