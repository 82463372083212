import * as React from 'react';
import PropTypes from 'prop-types';
import {
    ImageList,
    Box,
    Grid,
} from '@mui/material';
import {useState} from "react";
function BrandGrid(
    {
        brands,

    }
) {
    const [page, setPage] = useState(1);

    return (
        <Grid
            container={true}
            sx={{
                width: '100%',
                height: 'inherit',
                overflowY: 'auto',
            }}
            onScroll={(e) => {
                const bottom = e.target?.scrollHeight - e.target?.scrollTop === e.target?.clientHeight;
                if (bottom) {
                    const totalBrands = brands.length;
                    const currentBrands = 50 * page;
                    if (currentBrands < totalBrands) {
                        setPage(page + 1);
                    }
                }
            }}
        >
            {brands.slice(0, 50 * page).map( (el, index) => {
                return (
                    <Grid key={index} item={true} xs={2}>
                        <Box
                            sx={{
                                border: '1px solid #ccc',
                                justifyContent: 'center',
                                p: 1,
                                display: 'grid',
                                height: '100%',
                                maxHeight: '450px',
                                flexDirection: 'column',
                            }}
                        >
                            <Box
                                sx={{
                                    alignSelf: 'center',
                                    p: 1,
                                    background: `url("data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAIBAQIBAQICAgICAgICAwUDAwMDAwYEBAMFBwYHBwcGBwcICQsJCAgKCAcHCg0KCgsMDAwMBwkODw0MDgsMDAz/2wBDAQICAgMDAwYDAwYMCAcIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAAUABQDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD9pK838ff8jZd/8A/9AWj/AIT3Vv8An7/8hJ/hXSaBoFp4n0mK+vovPup8733Fd2CVHAIHQDtQBwdFekf8IDpP/Pp/5Ff/ABooA83r0jwD/wAinaf8D/8AQ2oooA2KKKKAP//Z")`,
                                    textAlign: 'center',
                                }}
                            >
                                <img
                                    src={`${el.iconUrl}`}
                                    style={{
                                        width: 'auto',
                                    }}
                                    alt={`${el.name}-${index}`}
                                    loading="lazy"
                                />
                            </Box>

                            <Box
                                sx={{
                                    alignSelf: "flex-end",
                                    fontWeight: 'bold',
                                    borderTop: '1px solid black',
                                    mt: 1,
                                }}
                            >
                                {el.name}
                            </Box>
                        </Box>
                    </Grid>
                )
            })
        }
        </Grid>
    )
}

BrandGrid.propTypes = {}

BrandGrid.defaultProps = {}

export default BrandGrid;
