import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import MenuButton from "../menuButton";
import Paper from "@mui/material/Paper";
import FeedbackTable from "./FeedbackTable";
import {useEffect, useState} from "react";
import {getFeedbackData} from "../../services/QaService";
import MediaDialog from "./MediaModal";

function FeedbackTableCard(
    {
        isAdmin,
        isAnnotator,
    }
) {
    const [rowsState, setRowsState] = useState({
        page: 1,
        pageSize: 50,
        rows: [],
        loading: true,
        totalRows: 0,
        companyUsage: 1,
        clientRequestHash: '',
    });

    const [isOpen, setOpen] = useState(false)
    const [mediaUrlState, setMediaUrlState] = useState('')

    const fetchFeedbackData = async () => {
        const params = {
            page: rowsState.page,
            pageSize: rowsState.pageSize,
        }
        if (!isAdmin) {
            params['companyUsage'] = rowsState.companyUsage
        }
        return await getFeedbackData(params)
    }

    useEffect( () => {
        let active = true;

        (async () => {
            setRowsState((prev) => ({ ...prev, loading: true }));
            try {
                const feedbackFromServer = await fetchFeedbackData();
                const { detectFeedback } = feedbackFromServer.data;
                const totalRows = +feedbackFromServer.headers["x-total-count"];
                setRowsState((prev) => (
                        {
                            ...prev,
                            rows: detectFeedback,
                            loading: false,
                            totalRows: totalRows,
                        }
                    )
                );
            }
            catch (e) {
                console.log(e);
                setRowsState((prev) => (
                        {
                            ...prev,
                            rows: [],
                            loading: false,
                            totalRows: 0,
                        }
                    )
                );
            }

        })();

        return () => {
            active = false;
        };
    }, [
        rowsState.page,
        rowsState.pageSize,
        rowsState.companyUsage,
    ]);

    const rowsOptionChange = (opt) => {
        setRowsState((prev) => ({
                ...prev,
                ...opt,
                loading: true,
            })
        )
    }



    const openMediaModla = (open) => (event) => {
        setOpen(open);
    }

    const setMediaUrl = (media) => {
        setMediaUrlState(media)
    }



    return(
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            {
                !isAdmin &&
                <AppBar position="static">
                    <Toolbar
                        disableGutters={true}
                        sx={{
                            background: '#FFF',
                        }}
                    >
                        <Box sx={{minWidth: 120}}>
                            <MenuButton onChange={rowsOptionChange}/>
                        </Box>
                    </Toolbar>
                </AppBar>
            }
            <Paper
                sx={{
                    height: '100%',
                }}
                elevation={0}
            >


            <MediaDialog
                 mediaViewModalState={isOpen}
                 setMediaViewModalState={setOpen}
                 mediaUrl={mediaUrlState}
            />

               <FeedbackTable
                   isAdmin={isAdmin}
                   rowsState={rowsState}
                   rowsOptionChange={rowsOptionChange}
                   isAnnotator={isAnnotator}
                   openMediaView={openMediaModla(true)}
                   setMediaUrl={setMediaUrl}
               />
            </Paper>
        </Card>
    )
}

export default FeedbackTableCard;
