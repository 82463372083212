import * as React from 'react';
import {DataGrid, GridOverlay} from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Button from "@mui/material/Button";
import CustomColumnMenuComponent from "../TableHelpers/customTableColMenu";
import { Link } from 'react-router-dom';
import CustomTableLoader from "../TableHelpers/CustomTableLoader";
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import LoopIcon from '@mui/icons-material/Loop';
import { putCvatTaskComplete } from '../../services/QaService';




export default function TaskTable({ tasks, isAnnotatorOrAdmin })
{


    const taskCol = [];

    tasks.map(task => {
        const { id, name, taskHash, status, developer, url } = task;
        taskCol.push({url: url, id: id, name: name, status: status, developer: isAnnotatorOrAdmin && developer ? developer.name : '', taskHash})
    });


    const completeTaskAsync = async (taskHash) => {
        const params = {}
        params['status'] = 'completed'
        return await putCvatTaskComplete(taskHash, params);
    }

    const putCompleteTask = ( row, api ) => {
        (async () => {
            try {
                const response = await completeTaskAsync(row.taskHash);
                const { qa } = response.status === 200 ? response.data : [];
                api.updateRows([{ id: row.id, status: qa.status}]);
                if(qa.id){
                    const itemIndex = tasks.findIndex(t => t.id === qa.id);
                    if(itemIndex > -1) {
                        tasks[itemIndex] = {...tasks[itemIndex],status:qa.status};
                    }
                }
             }
            catch (e) {
                console.log(e)
                alert(e)
            }
        })();
    }


    const openTask = url =>  window.open(url, '_blank').focus();
    const columns = [
        
        {
            field: 'details',
            headerName: 'Task',
            width: '30',
            sortable: false,
            renderCell: (params) => {
                const {row} = params;
                const {url} = row;
                return <IconButton color={'primary'} onClick={() => openTask(url)}><OpenInNewIcon color={'primary'}/></IconButton>;
            }
        },
        { field: 'name', headerName: 'ID', width: '160', sortable: false},
        {
            field: "status",
            headerName: "CVAT TASK",
            width: '90',
            sortable: false,
            renderCell: (params) => {
                const {row} = params;
                return <IconButton >{row.status === 1? <CheckIcon color={'success'} /> : <LoopIcon color={'warning'} />}</IconButton>;
            }
        },

        { field: 'developer', headerName: 'ASSIGNED TO', width: '180', sortable: false},
    ];

    if(isAnnotatorOrAdmin){
        const completeTask = {
            field: 'complete', headerName: 'CONFIRM',  sortable: false,
            renderCell: ({row, api}) => {
                const onClick = (e) => {
                    e.stopPropagation();
                    putCompleteTask(row, api)        
                };
                return <div className={'buttonContainer'}>
                        <Button variant="contained" size="small" onClick={onClick} disabled={row.status == 1 ? true : false}>DONE</Button>
                        </div>
            }

        }
        columns.splice(4, 0, completeTask);
    }

    
 

    return (
        <React.Fragment>
            <DataGrid
                autoHeight
                components={{
                    LoadingOverlay: CustomTableLoader,
                    ColumnMenu: CustomColumnMenuComponent,
                }}
                pageSize={10}
                density={'compact'}
                rows={taskCol}
                columns={columns}
                disableSelectionOnClick
            />
        </React.Fragment>
    );
}
