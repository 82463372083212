import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { getAnnotators, putAssignAnnotators } from "../../services/QaService";
import Grid from '@mui/material/Grid';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box';
import TaskList from './TaskList'; 

export default function TaskDialog({taskModalState, setTaskModalState, tasks, isAnnotatorOrAdmin}) {


    const handleClose = () => {
        setTaskModalState(false);
    }

    return (

        <Dialog
            aria-labelledby="customized-dialog-title"
            open={taskModalState}
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
            style={{
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                paddingBottom: 0,
            }}
        >


            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1} ><Typography variant="div" color="#02264D">TASK LIST</Typography></Box>
                    <Box>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <Divider />
            <DialogContent style={{
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 10,
                paddingBottom: 10,
            }}>
                { <TaskList tasks={tasks} isAnnotatorOrAdmin={isAnnotatorOrAdmin}/> }
                

            </DialogContent>
        </Dialog>

    );
}
