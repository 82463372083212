import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { getActivePlacements, getActiveBrands } from "../../services/QaService";
import BrandsList from "./BrandsTable";
import PlacementList from "./PlacementsTable";
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import LinkTab from "../LinkTab";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default function ProjectDialog(props) {
    const {
        modalState,
        setModalState,
        project
    } = props;

    const [tabsValue, setTabValue] = useState(0)
    const [urlState, setUrlState] = useState('')
    const [rowsState, setRowsState] = useState({
        page: 1,
        pageSize: 10,
        placementList: [],
        brandList: [],
        loading: true,
        totalRows: 0,
        rowHeight: 28,
    });

    const changeTab = (event, tabIndex) => {
        setTabValue(tabIndex)
    }

    const handleClose = () => {
        setModalState(false);
        setRowsState((prev) => (
            {
                ...prev,
                placementList: [],
                brandList: [],
                loading: false,
                totalRows: 0,
            }
        ));
    }


    const fetchActivePlacements = async () => {
        const params = {}
        if (project.projectHash.length > 0) {
            params['projectHash'] = project.projectHash
        }
        return await getActivePlacements(params);
    }

    const fetchActiveBrands = async () => {
        const params = {}
        if (project.projectHash.length > 0) {
            params['projectHash'] = project.projectHash
        }
        return await getActiveBrands(params);
    }

    useEffect(() => {
        setUrlState(`/library?projectHash=${project.projectHash}`)
        setTabValue(0);
    }, [props])


    useEffect(() => {
        let active = true;
        if (modalState) {
            (async () => {
                try {
                    setRowsState((prev) => ({ ...prev, loading: true }));
                    const brandsFromServer = await fetchActiveBrands();
                    const brands = brandsFromServer.status === 200 ? brandsFromServer.data.brands : [];
                    const totalRows = 0;
                    setRowsState((prev) => (
                        {
                            ...prev,
                            brandList: brands,
                            loading: false,
                            totalRows: totalRows,
                        }
                    ));
                }
                catch (e) {
                    setRowsState((prev) => (
                        {
                            ...prev,
                            brandList: [],
                            loading: false,
                            totalRows: 0,
                        }
                    )
                    );
                }

            })();
            (async () => {
                try {
                    setRowsState((prev) => ({ ...prev, loading: true }));
                    const placementsFromServer = await fetchActivePlacements();
                    const placements = placementsFromServer.status === 200 ? placementsFromServer.data.placements : [];
                    const totalRows = 0;
                    setRowsState((prev) => (
                        {
                            ...prev,
                            placementList: placements,
                            loading: false,
                            totalRows: totalRows,
                        }
                    ));
                }
                catch (e) {
                    setRowsState((prev) => (
                        {
                            ...prev,
                            placementList: [],
                            loading: false,
                            totalRows: 0,
                        }
                    )
                    );
                }

            })();
        }

        return () => {
            active = false;
        };
    }, [
        modalState
    ]);



    return (

        <Dialog
            aria-labelledby="customized-dialog-title"
            open={modalState}
            onClose={handleClose}
            fullWidth
            maxWidth="xl"
            style={{
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                paddingBottom: 0,
            }}
        >


            <DialogTitle >
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1} ><Typography variant="div" color="#02264D">Library</Typography></Box>
                    <Box>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent style={{
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                paddingBottom: 0,
            }}>

                <Tabs value={tabsValue} onChange={changeTab} aria-label="Library tabs" sx={{
                    background: '#E5E9ED',
                    px: 0,
                }}>
                    <LinkTab label="Brands" change={() => changeTab({}, 0)} value={0} href={urlState} />

                    {rowsState.placementList.length > 0 ? (
                        <LinkTab label="Placements" change={() => changeTab({}, 1)} value={1} href={urlState} />
                    ) : (
                        ""
                    )}

                </Tabs>



                <TabPanel value={tabsValue} index={0} >
                    <BrandsList rows={rowsState} />
                </TabPanel>
                {rowsState.placementList.length > 0 ? (
                    <TabPanel value={tabsValue} index={1}>
                        <PlacementList rows={rowsState} />
                    </TabPanel>
                ) : (
                    ""
                )}

            </DialogContent>
            <DialogActions>
            </DialogActions>
        </Dialog>
    );
}
