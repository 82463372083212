import * as React from 'react';
import PropTypes from 'prop-types';
import {
    GridColumnMenuContainer,
    HideGridColMenuItem,
    GridColumnsMenuItem,
}  from '@mui/x-data-grid';


function CustomColumnMenuComponent(props) {
    const { hideMenu, currentColumn,  ...other } = props;

        return (
            <GridColumnMenuContainer
                hideMenu={hideMenu}
                currentColumn={currentColumn}
                {...other}
            >
                <HideGridColMenuItem onClick={hideMenu} column={currentColumn} />
                <GridColumnsMenuItem onClick={hideMenu} column={currentColumn} />
            </GridColumnMenuContainer>
        );

}

CustomColumnMenuComponent.propTypes = {
    currentColumn: PropTypes.object.isRequired,
    hideMenu: PropTypes.func.isRequired,
};

export default CustomColumnMenuComponent;