import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

function FilterByProject(
    {
        options,
        onChange,
}) {

    return (
        <Autocomplete
            disablePortal
            size="small"
            id={'QADashboardProjectFilter'}
            options={options}
            onChange={onChange}
            style={{ width: 250 }}
            renderInput={(params) => <TextField {...params} label="Projects" />}
        />

    );
}

export default FilterByProject;
