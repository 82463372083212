import * as React from 'react';
import {useEffect, useState} from "react";
import {
    Skeleton,
    Box,
} from '@mui/material';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';

function ProgressiveImg(
    {
        placeholderSrc,
        src,
        ...props
    }
) {


    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);


    useEffect(_ => {
        setLoaded(false);
        setError(false);

        const img = new Image();
        img.src = src;
        img.onload = () => {
            setLoaded(true);
        };
        img.onerror = () => {
            setError(true);
        }
    }, [src])

    return (
        <>
            {
                !error?
                    <>
                        <img
                            src={src}
                            {...props}
                            alt={props.alt || ""}
                            style={loaded ? {} : {
                                visibility: 'hidden',
                                position: 'absolute',
                                top: '-999999999px',
                                left: '-999999999px',
                            }}
                        />
                        <Skeleton
                            variant={'rectangular'}
                            height={500}
                            width={'100%'}
                            style={loaded ? {display: 'none'} : {}}
                        />
                    </>
                    :
                    <Box
                        sx={{
                            height: 200,
                            width: '100%',
                            display: 'flex'
                        }}
                    >

                        <Box
                            sx={{
                                m: 'auto',
                                textAlign: 'center',
                            }}
                        >
                            <BrokenImageIcon
                                sx={{
                                    fontSize: '2rem',
                                    mb: 2,
                                }}
                            /><br/>
                            Image Error
                        </Box>
                    </Box>
            }


        </>

    )
}

ProgressiveImg.propTypes = {}

ProgressiveImg.defaultProps = {}

export default ProgressiveImg;
