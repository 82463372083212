import React, { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box';
import EmptyImage from './../../../src/empty.jpeg'

export default function MediaDialog(props) {
    const { mediaViewModalState, setMediaViewModalState, mediaUrl } = props;
    const [isvideo, setIsVideo] = useState(false)

    console.log(mediaUrl)

    const handleClose = () => {
        setMediaViewModalState(false);
    }

    useEffect(() => {
        let ext = mediaUrl.split('.').pop();
        setIsVideo(ext === 'mp4' ? true : false)
        console.log(ext)
        console.log(isvideo)
    })

    return (

        <Dialog
            aria-labelledby="customized-dialog-title"
            open={mediaViewModalState}
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
            style={{
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                paddingBottom: 0,
            }}
        >
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1} ><Typography variant="div" color="#02264D">Media</Typography></Box>
                    <Box>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <Divider />
            <DialogContent style={{
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                paddingBottom: 0,
            }}>
                {isvideo ?
                    <video width="600" height="400" controls autoPlay>
                        <source src={mediaUrl} type="video/mp4" />
                    </video>
                    :
                    <img src={mediaUrl ? mediaUrl : EmptyImage} alt="mediaUrl" width="600" height="400" />
                }
            </DialogContent>
            <Divider />
        </Dialog>
    );
}
