import * as React from "react";
import {Link} from 'react-router-dom';
import Tab from "@mui/material/Tab";

function LinkTab({label, href, change}) {
    return (
        <Tab key={label}
             label={label}
             component={Link}
             relative="true"
             onClick={change}
             to={href} />

    );
}

export default LinkTab;
