import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import VideoTableContainer from './VideoTable/VideoTableContainer';
import Tabs from '@mui/material/Tabs';
import Footer from "../layout/Footer";
import FeedbackTableCard from "./feedbackTable/FeedbackTableCard";
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';
import * as React from "react";
import ProjectDialog from './Library/LibraryDialog';
import LinkTab from './LinkTab'
import AnnotatorDialog from './Library/AnnotatorModal';
import TaskDialog from './VideoTable/TaskModal';

function Dashboard(
    {
        isAdmin,
        isArealAdmin,
        canAccessVideo,
        userProjects,
        isAnnotator,
        cvatUser,
        userPermission = [],
    }) {
    const [taskList, setTaskList] = useState([]);
    const [tabsValue, setTabValue] = useState(0)
    const [modalState, setModalState] = useState(false);
    const [selectedProject, setSelectedProject] = useState('');
    const [antModalState, setAntModalState] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState('');
    const [postAssignState, setPostAssignState] = useState(false);
    const [taskModalState, setTaskModalState] = useState(false);

    const openTaskModal = (open) => () => {
        setTaskModalState(open);
    }

    const setModalTask = (task) => {
        setTaskList(task)
    }

    const changeTab = (event, tabIndex) => {
        setTabValue(tabIndex)
    }

    const setProject = (project) => {
        setSelectedProject(project)
    }

    const openModal = (open) => (event) => {
        setModalState(open);
    }

    const setMedia = (media) => {
        setSelectedMedia(media)
    }

    const openAntModal = (open) => (event) => {
        setAntModalState(open);
    }

    useEffect(() => {
        let path = window.location.pathname;
        console.log(path)
        if (path.slice(-1) !== '/') path += '/'
        switch (path) {
            case '/':
                if (canAccessVideo) {
                    window.location.assign("/video");
                    setTabValue(0);
                } else {
                    window.location.assign("/feedback");
                    setTabValue(1);
                }
                break;
            case '/video':
                if (canAccessVideo) {
                    setTabValue(0);
                } else {
                    window.location.assign("/feedback");
                    setTabValue(1);
                }
                break;
            case '/feedback/':
                setTabValue(1);
                break;
            case '/library/':
                const params = new Map(window.location.search.slice(1).split('&').map(param => param.split('=')))
                if (params.get("projectHash")) {
                    setProject({projectHash: params.get("projectHash")})
                    setModalState(true)
                    setTabValue(0);
                } else {
                    console.error('Invalid parameters');
                }
                break;
        }
    }, [])

    return (
        <>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    overflow: 'auto',
                }}
            >
                <Router basename={'/'}>
                    <Box
                        sx={{
                            background: '#E5E9ED',
                            width: '100%',
                            px: 2,
                        }}
                    >
                        <Tabs value={tabsValue} onChange={changeTab} aria-label="QA Dashboard tabs">
                            {
                                canAccessVideo &&
                                <LinkTab label="Video" change={() => changeTab({}, 0)} value={0} href={'/video'}/>
                            }
                            <LinkTab label="Feedback" change={() => changeTab({}, 1)} value={1} href={'/feedback'}/>

                        </Tabs>
                    </Box>
                    <Container maxWidth={false} sx={{mt: 1}}>
                        <Grid container>
                            <Link
                                to={{
                                    pathname: "/video/",
                                }}
                            >
                                <ProjectDialog
                                    modalState={modalState}
                                    setModalState={setModalState}
                                    project={selectedProject}
                                />

                                <AnnotatorDialog
                                    antModalState={antModalState}
                                    setSelectedMedia={setAntModalState}
                                    media={selectedMedia}
                                    setPostAssign={setPostAssignState}
                                    tasks={taskList}
                                />

                                <TaskDialog
                                    taskModalState={taskModalState}
                                    setTaskModalState={setTaskModalState}
                                    tasks={taskList}
                                    isAnnotatorOrAdmin={isAnnotator || isAdmin}
                                />

                            </Link>

                            <Grid item xs={12}>
                                <Routes>
                                    <Route exact path={'/video'} element={
                                        <VideoTableContainer
                                            onRowClick={openTaskModal(true)}
                                            setTasks={setModalTask}
                                            isAdmin={isAdmin}
                                            onProjectColClick={openModal(true)}
                                            setProject={setProject}
                                            userProjects={userProjects}
                                            isAnnotator={isAnnotator}
                                            setMedia={setMedia}
                                            onAssignClick={openAntModal(true)}
                                            onPostAssign={postAssignState}
                                            cvatUser={cvatUser}
                                        />
                                    }/>
                                    <Route path={'/feedback'} element={
                                        <FeedbackTableCard
                                            isAdmin={isAdmin}
                                            isAnnotator={isAnnotator}
                                        />
                                    }/>
                                    <Route exact path={'/library'} element={
                                        <VideoTableContainer
                                            onRowClick={openTaskModal(true)}
                                            setTasks={setModalTask}
                                            isAdmin={isAdmin}
                                            onProjectColClick={openModal(true)}
                                            setProject={setProject}
                                            userProjects={userProjects}
                                            isAnnotator={isAnnotator}
                                            setMedia={setMedia}
                                            onAssignClick={openAntModal(true)}
                                            onPostAssign={postAssignState}
                                            cvatUser={cvatUser}
                                        />
                                    }/>
                                </Routes>
                            </Grid>
                        </Grid>
                    </Container>
                    <Footer/>
                </Router>
            </Box>
        </>
    );
}

export default Dashboard;
