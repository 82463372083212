import * as React from 'react';
import {DataGrid, GridRowId} from '@mui/x-data-grid';
import Button from "@mui/material/Button";
import {IconButton, Tooltip} from "@mui/material";
import CustomColumnMenuComponent from "../TableHelpers/customTableColMenu";
import CustomTableLoader from "../TableHelpers/CustomTableLoader";
import ThumbDownOffIcon from '@mui/icons-material/ThumbDown';
import ThumbUpOffIcon from '@mui/icons-material/ThumbUp';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import Badge from '@mui/material/Badge';
import {putDetectFeedbackApprove} from '../../services/QaService';

export default function FeedbackTable(
    {
        rowsState,
        isAdmin,
        rowsOptionChange,
        isAnnotator,
        openMediaView,
        setMediaUrl,
    }
) {

    const taskCol = [];
    rowsState.rows.forEach(item => {
        const {
            requestHash, type, comment, dateEntered,
            media, id, status, project, approved, commentResponse,
        } = item;
        let sessionIdValue = '';
        let mediaUrlValue = '';
        let mediaIdValue = '';
        let mediaAnnotateUrlValue = '';
        if (media) {
            const {sessionId, mediaUrl, mediaAnnotateUrl} = media;
            if (sessionId) {
                sessionIdValue = sessionId;
            }
            if (mediaUrl) {
                mediaUrlValue = mediaUrl;
            }
            if (media.id) {
                mediaIdValue = media.id;
            }
            if (mediaAnnotateUrl) {
                mediaAnnotateUrlValue = mediaAnnotateUrl;
            }
        }

        const rowData = {
            id: requestHash,
            requestHash,
            type,
            comment,
            dateEntered,
            sessionIdValue,
            mediaUrlValue,
            mediaAnnotateUrlValue,
            status,
            approved,
            commentResponse,
        }

        if (isAdmin){
            rowData['rowId'] = id;
            rowData['mediaId'] = mediaIdValue;
            rowData['projectText'] = project ? `${project.detectionSettingId} - ${project.name}` : '';
            rowData['projectId'] = project ? project.detectionSettingId : '#';
        } else {
            rowData['projectCol'] = project ? `${project.projectHash} - ${project.name}` : '';
        }
        taskCol.push(rowData);
    })

    const approveFeedbackAsync = async (status, requestHash) => {
        const params = {}
        params['approved'] = status
        return await putDetectFeedbackApprove(requestHash, params);
    }

    const approveFeedback = (status, row, api) => {
        (async () => {
            try {
                const response = await approveFeedbackAsync(status, row.requestHash);
                const {detectFeedback} = response.status === 200 ? response.data : [];
                api.updateRows([{id: row.id, approved: status === 1 ? detectFeedback.approved : row.approved - 1}]);
            } catch (e) {
                console.log(e)
            }
        })();
    }

    const columns = [
        {
            field: 'requestHash',
            headerName: 'Hash',
            sortable: false,
            flex: 0.2,
        },
        {
            field: 'type',
            headerName: 'Type',
            sortable: false,
            flex: 0.4,
        },
        {
            field: 'comment',
            headerName: 'Comment',
            sortable: false,
            flex: 1.5,
            renderCell: (params) => {
                const {row} = params
                const {comment} = row;
                if (comment) {
                    return (
                        <Tooltip title={comment} arrow>
                            <span>{comment}</span>
                        </Tooltip>
                    );
                } else {
                    return (<span/>)
                }

            }
        },
        {
            field: 'commentResponse',
            headerName: 'Comment response',
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                const {row} = params
                const {commentResponse} = row;
                if (commentResponse) {
                    return (
                        <Tooltip title={commentResponse} arrow>
                            <span>{commentResponse}</span>
                        </Tooltip>
                    );
                } else {
                    return (<span/>)
                }

            }
        },
        {
            field: 'dateEntered',
            headerName: 'Date entered',
            width: '180',
            sortable: false,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: '120',
            sortable: false,
        },
    ];

    if (isAdmin) {
        const colId = {
            field: 'rowId',
            headerName: 'id',
            sortable: false,
            flex: 0.2,
        };
        const mediaRow = {
            field: 'sessionIdValue',
            headerName: 'media id - session id',
            width: '230',
            sortable: false,
            renderCell: (params) => {
                const {row} = params
                const {mediaId, sessionIdValue, mediaUrlValue, mediaAnnotateUrlValue} = row;
                const onClick = (e) => {
                    e.stopPropagation();
                    if (mediaAnnotateUrlValue) {
                        setMediaUrl(mediaAnnotateUrlValue)
                    }
                    openMediaView(true)
                };

                return (
                    <Button
                        sx={{
                            textTransform: 'none',
                        }}
                        variant={'text'}
                        onClick={onClick}
                    >
                        {mediaId} - {sessionIdValue}
                    </Button>
                )
            }
        };

        const openLink = url => window.open(`https://my.visua.com/backoffice/admin/detectionSettings/${url}`, '_blank').focus();
        const colProjectLink = {
            field: 'projectId',
            headerName: 'Project',
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                const {row} = params;
                const {projectId, projectText} = row;
                return (
                    <Tooltip title={projectText} arrow>
                        <Button variant="text" onClick={() => !isAnnotator ? openLink(projectId) : ''}>
                            {projectText}
                        </Button>
                    </Tooltip>
                )
            }
        }

        columns.splice(0, 0, colId);
        columns.splice(3, 0, mediaRow);
        columns.splice(7, 0, colProjectLink);


    } else {
        const mediaRow = {
            field: 'sessionIdValue',
            headerName: 'session id',
            sortable: false,
            width: '180',
            renderCell: (params) => {
                const {row} = params
                const {sessionIdValue, mediaUrlValue} = row;
                return (
                    <Button
                        sx={{
                            textTransform: 'none',
                        }}
                        variant={'text'}
                        onClick={() => window.open(mediaUrlValue, '_blank').focus()}
                    >
                        {sessionIdValue}
                    </Button>
                )
            }
        }
        columns.splice(4, 0, mediaRow);
        columns.splice(7, 0, {
            field: 'projectCol',
            headerName: 'Project',
            width: 200,
            sortable: false,
            renderCell: (params) => {
                const {row} = params;
                const {projectCol} = row;
                return (
                    <Tooltip title={projectCol} arrow>
                        <span>
                            {projectCol}
                        </span>
                    </Tooltip>
                )
            }
        })
    }

    const DownThumbButton = (row, onDownClick) => (
        <Badge
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            overlap="circular"
            sx={{"& .MuiBadge-badge": {fontSize: 9, color: '#E1523E'}}}
            badgeContent={row.approved === 1 ? '' : (row.approved < 0 ? (-1 * row.approved) : '')}
        >
            <IconButton disabled={row.approved === 1} onClick={onDownClick}>
                {row.approved === 0 ? <ThumbDownOutlinedIcon color='primary'/> : <ThumbDownOffIcon
                    sx={{color: (row.approved === 1 ? '' : (row.approved < 0 ? '#E1523E' : ' #41B6E6 '))}}/>}
            </IconButton>
        </Badge>
    );

    const UpThumbButton = (row, onUpClick) => (
        <IconButton disabled={row.approved === 1} onClick={onUpClick}>
            {row.approved === 0 || row.approved < 0 ? <ThumbUpOutlinedIcon color='primary'/> :
                <ThumbUpOffIcon sx={{color: (row.approved === 1 ? '#84BD00' : ' #41B6E6 ')}}/>}
        </IconButton>
    );

    const approved = {
        field: 'approved',
        headerName: 'Approved',
        sortable: false,
        renderCell: ({row, api}) => {
            const onDownClick = (e) => {
                e.stopPropagation();
                approveFeedback(-1, row, api)
            };
            const onUpClick = (e) => {
                e.stopPropagation();
                approveFeedback(1, row, api)
            };
            return (<div>
                {DownThumbButton(row, onDownClick)}
                {UpThumbButton(row, onUpClick)}
            </div>)
        }
    }
    columns.splice(8, 0, approved);


    return (
        <React.Fragment>
            <DataGrid
                autoHeight
                components={{
                    LoadingOverlay: CustomTableLoader,
                    ColumnMenu: CustomColumnMenuComponent,
                }}
                pageSize={rowsState.pageSize}
                rowsPerPageOptions={[25, 50, 100]}
                pagination
                rowCount={rowsState.totalRows}
                loading={rowsState.loading}
                paginationMode="server"
                density={'compact'}
                rows={taskCol}
                columns={columns}
                disableSelectionOnClick
                onPageChange={(page) => {
                    rowsOptionChange({page: page + 1})
                }
                }
                onPageSizeChange={size => {
                    rowsOptionChange({pageSize: size})
                }
                }
            />
        </React.Fragment>
    )

}
