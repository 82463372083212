import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { getAnnotators, putAssignAnnotators } from "../../services/QaService";
import Grid from '@mui/material/Grid';
import AnnotatorList from "./AnnotatorList";
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box';

export default function AnnotatorDialog(props) {
    const { antModalState, setSelectedMedia, media, setPostAssign, tasks } = props;
    const [annotatorsState, setAnnotatorsState] = useState([]);
    const [defaultCheck, setDfaultCheck] = useState(true);

    const handleClose = () => {
        setSelectedMedia(false);
        setAnnotatorsState([])
        setRowsState((prev) => (
            {
                ...prev,
                annotatorsList: [],
                loading: false,
                totalRows: 0,
                isAssigning: false,
            }
        ));
    }

    const setAnnotators = (annotators) => {
        setAnnotatorsState(annotators)
    }

    const [rowsState, setRowsState] = useState({
        page: 1,
        pageSize: 10,
        annotatorsList: [],
        loading: true,
        totalRows: 0,
        rowHeight: 28,
        isAssigning: false,
    });

    const fetchAnnotators = async () => {
        const params = {}
        return await getAnnotators(params);
    }

    const assignAnnotators = async () => {
        const params = {}
        if (annotatorsState.length > 0) {
            const array = [...annotatorsState].join(',')
            console.log(array)
            params['developerHash'] = array
        }
        return await putAssignAnnotators(media, params);
    }


    const test = () => {
        console.log(annotatorsState)
    }

    const assignTask = () => {
        (async () => {
            try {
                setRowsState((prev) => ({ ...prev, isAssigning: true }));
                const mediaFromServer = await assignAnnotators();
                const { qa } = mediaFromServer.status === 200 ? mediaFromServer.data : [];
                if(qa.length > 0){
                    qa[0].tasks.forEach(task => {
                        const itemIndex = tasks.findIndex(t => t.id === task.id);
                        if(itemIndex > -1) {
                            tasks[itemIndex] = task;
                        } else {
                            tasks = tasks.push(task);
                        }       
                    });
                }
                setDfaultCheck(false);
                setSelectedMedia(false);
                setAnnotatorsState([])
                setPostAssign(true)
                setRowsState((prev) => (
                    {
                        ...prev,
                        loading: false,
                        totalRows: 0,
                        isAssigning: false,
                    }
                ));
            }
            catch (e) {
                setSelectedMedia(false);
                setAnnotatorsState([])
                setRowsState((prev) => (
                    {
                        ...prev,
                        annotatorsList: [],
                        loading: false,
                        totalRows: 0,
                        isAssigning: false,
                    }
                ));
            }
        })();
    }


    useEffect(() => {
        let active = true;
        if (antModalState) {
            (async () => {
                try {
                    setRowsState((prev) => ({ ...prev, loading: true }));
                    const annotatorsFromServer = await fetchAnnotators();
                    const { qa } = annotatorsFromServer.status === 200 ? annotatorsFromServer.data : [];
                    const totalRows = 0;
                    setRowsState((prev) => (
                        {
                            ...prev,
                            annotatorsList: qa,
                            loading: false,
                            totalRows: totalRows,
                        }
                    ));
                }
                catch (e) {
                    setRowsState((prev) => (
                        {
                            ...prev,
                            annotatorsList: [],
                            loading: false,
                            totalRows: 0,
                        }
                    )
                    );
                }

            })();
        }
        return () => {
            active = false;
        };
    }, [
        antModalState
    ]);


    return (

        <Dialog
            aria-labelledby="customized-dialog-title"
            open={antModalState}
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
            style={{
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                paddingBottom: 0,
            }}
        >


            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1} ><Typography variant="div" color="#02264D">Annotators</Typography></Box>
                    <Box>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <Divider />
            <DialogContent style={{
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                paddingBottom: 0,
            }}>
                <AnnotatorList rows={rowsState} setAnnotators={setAnnotators} defaultCheck={defaultCheck}></AnnotatorList>
                

            </DialogContent>
            <Divider />
            <DialogActions>

                <LoadingButton
                    disabled={annotatorsState.length==0}
                    loading={rowsState.isAssigning}
                    loadingPosition="start"
                    size="small"
                    color="primary"
                    startIcon={<SendIcon aria-label="assign" />}
                    variant="contained"
                    onClick={assignTask}
                >
                    Assign
                </LoadingButton>
            </DialogActions>
        </Dialog>

    );
}
