import React, {useEffect, useState} from "react";
import {
    Divider,
    Grid,
    Paper,
    Card,
    Stack,
    Typography, Switch,
} from "@mui/material";

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ProgressiveImg from "../ProgressiveImg/ProgressiveImg";
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';
import Box from "@mui/material/Box";
import ViewListIcon from '@mui/icons-material/ViewList';
import GridOnIcon from '@mui/icons-material/GridOn';
export default function PlacementList(
    {
        rows,
        contentRef,
        fullscreen,
    }
) {
    const [placementData, setPlacementData] = useState(rows.placementList);
    const [selectImg, setSelectImg] = useState({
        rasterUrl: '',
    });
    const [listHeight, setListHeight] = useState(500);
    const [tableView, setTableView] = useState(false)
    useEffect(() => {
        setPlacementData(rows.placementList.sort((a, b) => {
            return a.name.localeCompare(
                b.name,
                undefined,
                {
                    numeric: true,
                    sensitivity: 'base'
                })
        }))
    }, [rows])

    const onclickImg = (item) => {
        if (tableView) setTableView(false)
        setSelectImg(item);
    }
    useEffect(_ => {
            console.log({fullscreen})
            if (fullscreen) {
                if (!!contentRef && !!contentRef.current && !!contentRef.current.clientHeight) {
                    setListHeight(contentRef.current.clientHeight - 150);
                }
            } else {
                setListHeight(500)
            }
        },
        [
            fullscreen,
        ])

    console.log({placementData})
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Paper
                sx={{
                    height: '100%',
                }}
                elevation={0}
            >
                <Grid container>
                    <Grid item sx={{ml: 'auto'}}>
                        <Box
                            sx={{
                                ml: 'auto',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <ViewListIcon
                                sx={{
                                    color: !tableView ? 'primary.main' : 'currentColor',
                                }}
                            />
                            <span
                                style={{
                                    color: !tableView ? '#1DB8E0' : 'currentColor',
                                }}
                            >
                                List
                            </span>
                            <Switch
                                checked={tableView}
                                onChange={(evt, value) => {
                                    setTableView(value)
                                }}
                            />
                            <GridOnIcon
                                sx={{
                                    color: tableView ? 'primary.main' : 'currentColor',
                                }}
                            />
                            <span
                                style={{
                                    color: tableView ? '#1DB8E0' : 'currentColor',
                                }}
                            >Grid</span>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    {
                        placementData.length === 0 ?
                            <Grid
                                item
                                xs={12}
                            >
                                <Typography
                                    variant={'h4'}
                                    sx={{
                                        m: 4,
                                        textAlign: 'center'
                                    }}
                                >
                                    No placements found
                                </Typography>
                            </Grid>
                            :
                            <>
                                <Grid
                                    item
                                    xs={!tableView? 2: 12}
                                >
                                    <ImageList
                                        sx={{
                                            height: listHeight,
                                        }}
                                        cols={!tableView? 1: 4}
                                    >
                                        {placementData

                                            .map((item, index) => (
                                                <ImageListItem
                                                    key={index}
                                                    onClick={() => onclickImg(item)}
                                                    sx={{
                                                        cursor: 'pointer',
                                                        mb: 1,
                                                        p: 1,
                                                        outline: '1px solid #f2f2f2'
                                                    }}
                                                >
                                                    <ProgressiveImg
                                                        src={item.iconUrl}
                                                        srcSet={item.iconUrl}
                                                        alt={item.title}
                                                        loading="lazy"
                                                        width='100%'
                                                    />
                                                    <ImageListItemBar
                                                        title={item.name}
                                                        subtitle={<span>ID: {item.id}</span>}
                                                        position="below"
                                                    />
                                                </ImageListItem>
                                            ))}
                                    </ImageList>
                                </Grid>
                                <Divider/>
                                {!tableView &&


                                <Grid item  xs={!tableView? 10: 0}>
                                    <Paper
                                        elevation={0}
                                        sx={{
                                            height: listHeight,
                                            overflow: 'auto',
                                            display: selectImg.rasterUrl === '' ? 'flex' : 'block'
                                        }}
                                    >
                                        {selectImg.rasterUrl === '' &&
                                            <Typography
                                                variant={'h4'}
                                                align={'center'}
                                                sx={{
                                                    m: 'auto',
                                                }}
                                            >
                                                <PhotoCameraBackIcon
                                                    sx={{
                                                        fontSize: '2.2rem'
                                                    }}
                                                /> <br/>
                                                No placement selected
                                            </Typography>
                                        }


                                        {selectImg.rasterUrl !== '' &&
                                            <Stack
                                                direction='row'
                                                spacing={1}
                                                justifyContent="space-between"
                                                alignItems="center">
                                                <Typography sx={{p: 1}}><b>Name</b>: {selectImg.name}</Typography>
                                                <Typography sx={{p: 1}}><b>ID</b>: {selectImg.id}</Typography>
                                            </Stack>
                                        }

                                        {selectImg.rasterUrl !== '' &&
                                            <ProgressiveImg
                                                src={selectImg.rasterUrl}
                                                srcSet={selectImg.rasterUrl}
                                                alt={selectImg.title}
                                                width='100%'
                                            />
                                        }

                                    </Paper>
                                </Grid>
                                }
                            </>
                    }


                </Grid>


            </Paper>
        </Box>
    );
}
