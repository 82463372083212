import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from "@mui/material/Typography";
import * as React from "react";
import {styled} from "@mui/material/styles";
import Button from '@mui/material/Button';
import CvatUserInfo from './CvatUserInfoPopup';

const drawerWidth = 350;

const AppBar = styled(
    MuiAppBar,
    {
        shouldForwardProp: (prop) => prop !== 'open',
    })(
    ({theme, open}) => ({
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            ...(open && {
                marginRight: drawerWidth,
                width: `calc(100% - ${drawerWidth}px)`,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            }),
        }
    )
);

const Header = ({userData}) => {
    const [open] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open2 = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
      };

    return (
        <AppBar position="absolute" open={open}
                sx={{
                    backgroundColor: 'background.paper',
                    color: 'primary.main'
                }}
        >
            {open2 && userData.cvatUser && <CvatUserInfo open={open2} onClose={handleClose} anchorEl={anchorEl} cvatUser={userData.cvatUser} />}
            <Toolbar
                sx={{
                    pr: '24px',
                }}
            >
                <div className="qaLogo"/>
                <Typography
                    align={'left'}
                    component="h1"
                    variant="h6"
                    color="#02264D"
                    noWrap
                    sx={{flexGrow: 1}}
                >
                    QA Dashboard
                </Typography>
                <Button
                    id="basic-button"
                    aria-controls={open2 ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open2 ? 'true' : undefined}
                    onClick={handleClick}
                    sx={{
                        textTransform: 'lowercase',
                        mr: 2,
                        typography: 'body1'
                    }}
                >
                    {userData.developer.name}
                </Button>
                <a href="https://my.visua.com">
                    <div className="navbar-brand"/>
                </a>
            </Toolbar>
        </AppBar>
    )
}

export default Header;
