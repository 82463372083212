import React, { useEffect, useState,useMemo } from "react";
import { styled } from '@mui/material/styles';
import { DataGrid} from '@mui/x-data-grid';
import CustomTableLoader from "../TableHelpers/CustomTableLoader";
import {
    Paper,
    Card,
    AppBar,
    Tooltip,
    Toolbar,
    Typography,
    Button,
    InputBase, Switch,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { DataGridPro } from '@mui/x-data-grid-pro';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Box from "@mui/material/Box";
import TableChartIcon from '@mui/icons-material/TableChart';
import CollectionsIcon from '@mui/icons-material/Collections';
import BrandGrid from "./BrandGrid";
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    width: 'fit-content',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: theme.palette.secondary.main,
    marginRight: 0,
    '& .MuiInputBase-input': {
        marginRight: 0,
        padding: theme.spacing(1, 1, 1, 1),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

export default function BrandsList({ rows }) {
    const [brandsData, setBrandsData] = useState(rows.brandList);
    const [searchValue, setSearchValue] = useState('');
    const [tablePage, setTablePage] = useState(1);
    const [tableView, setTableView] = useState(true);
    const brandColumns = useMemo(() => [
        {
            field: 'name',
            headerName: 'Name',
            width: 150
        },
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'logoVersionArray',
            headerName: 'Logo Version',
            padding: 100,
            renderCell: (params) => {
                const { row } = params;
                const { logoVersionArray } = row;
                const images = logoVersionArray.map((logo,index) => (
                    <div className={'imgContainer'} key={index}>
                        <Tooltip placement="right-end" title={
                                <img src={logo.iconUrl} alt={logo.name} width={250} />
                        }>
                            <img src={logo.iconUrl} alt={logo.name} style={{cursor:'pointer'}}/>
                        </Tooltip>

                    </div>
                ))
                return (
                    <div className={'logoRow'}>
                        {images}
                    </div>
                )
            },
            flex: 1,
        }
    ], []);

    const brandRows = [];

    if(brandsData){
        brandsData.sort((one, two) => (one.name > two.name) ? 1 : -1);
    }

    brandsData.map(element => {
        const { id, name, logoVersionArray } = element;
        brandRows.push({ id: id, name: name, logoVersionArray: logoVersionArray })
    });

    const updateSearchText = (e) => {
        setSearchValue(e.target.value)
    }

    const filterBrand = (e) => {
        if (searchValue.length > 0) {
            const filterData = rows.brandList.filter(item => (String(item.name).toLowerCase()).includes(searchValue.toLowerCase()))
            setBrandsData(filterData)
        } else {
            setBrandsData(rows.brandList)
        }
    }


    useEffect(() => {
        setBrandsData(rows.brandList);
        setSearchValue('');
        setTablePage(1);
    }, [rows])

    useEffect(() => {
        filterBrand()
    }, [searchValue])

    const logos = brandsData.map(el => el.logoVersionArray).flat();

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    mb: 2,
                }}
            >
            <Box>
                <Search sx={{
                    mx: 0
                }}>
                    <StyledInputBase
                        id={'QADashboardSearch'}
                        placeholder="Search by name…"
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={(e) => updateSearchText(e)}
                    />
                    <Button
                        color={'inherit'}
                        sx={{
                            backgroundColor: '#1DB8E0',
                            height: '40px',
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            '&:hover': {
                                backgroundColor: '#02264D'
                            }
                        }}
                        onClick={filterBrand}
                    >
                        <SearchIcon />
                    </Button>
                </Search>
            </Box>
                {
                    true &&
                    <Box
                        sx={{
                            ml: 'auto',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >


                        <CollectionsIcon
                            sx={{
                                color: !tableView ? 'primary.main' : 'currentColor',
                            }}
                        />
                        <span
                            style={{
                                color: !tableView ? '#1DB8E0' : 'currentColor',
                            }}
                        >
                    Collection
                </span>
                        <Switch
                            checked={tableView}
                            onChange={(evt, value) => {
                                setTableView(value)
                            }}
                        />
                        <TableChartIcon
                            sx={{
                                color: tableView ? 'primary.main' : 'currentColor',
                            }}
                        />
                        <span
                            style={{
                                color: tableView ? '#1DB8E0' : 'currentColor',
                            }}
                        >Table</span>
                    </Box>
                }

            </Box>
            <Paper
                sx={{
                    height: '600px',
                }}
                elevation={0}
            >
                {
                    tableView ?
                    <DataGridPro
                        paginationModel={{
                            pageSize: 200,
                            page: tablePage,
                        }}
                        pageSizeOptions={[50, 100, 200, 500]}
                        rows={brandRows}
                        rowHeight={60}
                        pagination={true}
                        columns={brandColumns}
                        loading={rows.loading}
                        onPaginationModelChange={(prop) => setTablePage(prop.page)}
                    />
                    :
                    <BrandGrid
                        brands={logos}
                    />
                }

                {
                    false &&

                                   <ImageList
                                       sx={{ width: '100%', height: '100%' }}
                                       cols={5}
                                       rowHeight={250}
                                   >
                    {logos.map((item, index) => (
                        <ImageListItem
                            key={item.iconUrl}
                            sx={{
                                display: 'block',
                                overflow: 'hidden',
                                cursor: 'pointer',
                                background:`url("data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAIBAQIBAQICAgICAgICAwUDAwMDAwYEBAMFBwYHBwcGBwcICQsJCAgKCAcHCg0KCgsMDAwMBwkODw0MDgsMDAz/2wBDAQICAgMDAwYDAwYMCAcIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAAUABQDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD9pK838ff8jZd/8A/9AWj/AIT3Vv8An7/8hJ/hXSaBoFp4n0mK+vovPup8733Fd2CVHAIHQDtQBwdFekf8IDpP/Pp/5Ff/ABooA83r0jwD/wAinaf8D/8AQ2oooA2KKKKAP//Z")`,
                                padding: 1,
                                height: 200,
                                borderLeft: (index !== 0 && logos[index - 1].name !== item.name)?'5px solid red' : null,
                            }}
                        >
                            <img
                                src={`${item.iconUrl}`}
                                style={{
                                    width: 'auto',
                                    height: '100%',
                                }}
                                alt={item.name}
                                loading="lazy"
                            />
                            <ImageListItemBar
                                title={item.name}
                                actionIcon={
                                    <IconButton
                                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                        aria-label={`info about ${item.name}`}
                                    >
                                        <InfoIcon />
                                    </IconButton>
                                }
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            />
                        </ImageListItem>
                    ))}
                </ImageList>

                }
            </Paper>
        </>
    );
}

