import * as React from 'react';
import {DataGrid, GridOverlay} from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Button from "@mui/material/Button";
import CustomColumnMenuComponent from "../TableHelpers/customTableColMenu";
import { Link } from 'react-router-dom';
import CustomTableLoader from "../TableHelpers/CustomTableLoader";
import SendIcon from '@mui/icons-material/Send';




export default function VideoTable(
    {
        tableOptionChange,
        onRowClick,
        setTasks,
        rowsState,
        isAdmin,
        onProjectColClick,
        setProject,
        isAnnotator,
        setMedia,
        onAssignClick,
        isAssigning,
    })
{
    const taskCol = [];

    rowsState.rows.forEach(item => {
        const round = (num) => Math.round((num + Number.EPSILON) * 100) / 100
 
        const {media, tasks, project} = item;

        const { sessionId, dateEntered} = media;
        const tasksLength = tasks.length;
        const totalTasksCompleted = tasks.filter(task => task.status === 1).length;


        const rowData = {
            id: sessionId,
            media: sessionId,
            tasksLength,
            dateEntered,
            totalTasksCompleted,
            QAStatus: round((totalTasksCompleted / tasksLength) * 100),
            completionPercentage: media.completionPercentage,
            tasks: tasks,
        }

        if (isAdmin) {
           rowData['mediaId'] = media.id;
           rowData['projectText'] = project? `${project.detectionSettingId} - ${project.name}`: '';
           rowData['projectId'] = project? project.detectionSettingId : '#';
        } else {
            if (project) {
                rowData['projectCol'] = project? `${project.projectHash} - ${project.name}` : '';
            }
        }
        rowData['library'] = project? project : '';
        taskCol.push(rowData);
    })

    const columns = [
        { field: 'media', headerName: 'Session id', sortable: false},
        { field: 'tasksLength', headerName: 'N° of tasks', width: '130', sortable: false},
        { field: 'completionPercentage', headerName: 'Detection Status (%)', width: '180', sortable: false},
        { field: 'dateEntered', headerName: 'Date entered', width: '180', sortable: false},
        {
            field: 'QAStatus',
            headerName: 'QA status',
            width: '150',
            sortable: false,
            renderCell: (params)=> {
                const {row} = params;
                const {tasksLength, totalTasksCompleted, QAStatus} = row;
                return `${totalTasksCompleted}/${tasksLength} (${QAStatus}%)`
            }
        },
        {
            field: "action",
            headerName: "Details",
            sortable: false,
            renderCell: (params) => {
                const onClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    const {row} = params;
                    if (row) {
                        const {tasks} = row;
                        setTasks(tasks);
                    }
                    onRowClick(true);
                };
                return <IconButton color={'primary'} onClick={onClick}><OpenInNewIcon color={'primary'}/></IconButton>;
            }
        },
    ];

    if (isAdmin) {
        const colId = {
            field: 'mediaId', headerName: 'Media id', sortable: false,
        }
        const openLink = url =>  window.open(`https://my.visua.com/backoffice/admin/detectionSettings/${url}`, '_blank').focus();
        const colProjectLink = {
            field: 'projectId', headerName: 'Project', width: 340, sortable: false,
            renderCell: (params) => {
                const {row} = params;
                const {projectId, projectText} = row;
                return <Button variant="text" onClick={() => !isAnnotator ? openLink(projectId) : ''}>{projectText}</Button>
            }
        }

        columns.splice(0, 0, colId);
        columns.splice(6, 0, colProjectLink);
    } else {
        columns.splice(5, 0, {
            field: 'projectCol', headerName: 'Project', width: 200, sortable: false,
        })
    }

    const openProject = {
        field: 'library', headerName: 'Library', sortable: false,
        renderCell: (params) => {
            const {row} = params;
            const {library} = row;
            const onClick = (e) => {
                e.stopPropagation(); // don't select this row after clicking
                if (row) {
                    setProject(library);
                }
                onProjectColClick(true)

            };
            let proHash = `/library?projectHash=${library.projectHash}`
            return <Link to={proHash} onClick={onClick}><OpenInNewIcon color={'primary'}/></Link>
        }

    }
    columns.splice(8, 0, openProject);

    if(isAdmin | isAnnotator){
        const assignTask = {
            field: 'assign', headerName: 'Assign Task', width: 150, sortable: false,
            renderCell: (params) => {
                const {row} = params;
                const {media, tasksLength, totalTasksCompleted, tasks} = row;
                const onClick = (e) => {
                    e.stopPropagation();
                    console.log(row)
                    if (row) {
                        setMedia(media);
                        onAssignClick(true)
                        setTasks(tasks);
                    }                
                };
                return <div className={'buttonContainer'}>
                        <Button variant="contained" size="small" onClick={onClick} endIcon={<SendIcon aria-label="assign" />} disabled={totalTasksCompleted == tasksLength ? true : false}>Assign</Button>
                        </div>
            }

        }
        columns.splice(9, 0, assignTask);
    }

    
 

    return (
        <React.Fragment>
            <DataGrid
                autoHeight
                components={{
                    LoadingOverlay: CustomTableLoader,
                    ColumnMenu: CustomColumnMenuComponent,
                }}
                pageSize={rowsState.pageSize}
                rowsPerPageOptions={[25, 50, 100]}
                pagination
                rowCount={rowsState.totalRows}
                loading={rowsState.loading}
                paginationMode="server"
                density={'compact'}
                rows={taskCol}
                columns={columns}
                disableSelectionOnClick
                onRowDoubleClick={(params, event) => {
                    const {row} = params;
                    if (row) {
                        const {tasks} = row;

                        setTasks(tasks);
                    }
                    onRowClick(true);
                }}
                onPageChange={ (page) =>{
                    tableOptionChange({page: page + 1}) }
                }
                onPageSizeChange={size => {
                    tableOptionChange({pageSize: size}) }
                }
            />
        </React.Fragment>
    );
}
