import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Card from '@mui/material/Card';
import QaTable from './VideoTable';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import MenuButton from '../menuButton'
import Button from "@mui/material/Button";
import { getQaData } from "../../services/QaService";
import QaFilterMenu from '../Library/QaFilterMenu';
import TableFilter from '../autocomplete/TableFilter';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Tooltip, Typography } from '@mui/material';


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: theme.palette.secondary.main,
    marginRight: 0,
    '& .MuiInputBase-input': {
        marginRight: 0,
        padding: theme.spacing(1, 1, 1, 1),
        // vertical padding + font size from searchIcon
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

function VideoTableContainer(
    {
        onRowClick,
        setTasks,
        isAdmin,
        onProjectColClick,
        setProject,
        userProjects,
        isAnnotator,
        setMedia,
        onAssignClick,
        cvatUser,
    }) {

    const [rowsState, setRowsState] = useState({
        page: 1,
        pageSize: 25,
        rows: [],
        loading: true,
        totalRows: 0,
        companyUsage: 1,
        clientRequestHash: '',
        rowHeight: 28,
        qaStatus: 0,
        projectHash: '',
    });


    const [projectValue, setProjectValue] = useState('');
    const [projectInputValue, setProjectInputValue] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [assignToMeValue, setAssignToMeValue] = useState(false);

    const projectValueOnChange = (event, newValue) => {
        setProjectValue(newValue);
        setRowsState((prev) => ({
            ...prev,
            projectHash: newValue ? newValue['id'] : ''
        })
        )
    }

    const projectInputOnChange = (event, newInputValue) => {
        setProjectInputValue(newInputValue);
    }

    const activateSearch = () => {
        rowsOptionChange({
            clientRequestHash: searchValue
        })
    }

    const updateSearchText = (e) => {
        setSearchValue(e.target.value)
    }

    const assignToMeOnChange = (event) => {
        setAssignToMeValue(event.target.checked);
    }


    const fetchMediaData = async () => {
        const params = {
            page: rowsState.page,
            pageSize: rowsState.pageSize,
        }
        if (!isAdmin) {
            params['companyUsage'] = rowsState.companyUsage
        }

        if (rowsState.clientRequestHash.length > 0) {
            params['sessionId'] = rowsState.clientRequestHash
        }

        if (rowsState.qaStatus >= 0) {
            params['qaStatus'] = rowsState.qaStatus
        }

        if (rowsState.projectHash.length > 0) {
            params['projectHash'] = rowsState.projectHash
        }

        if (assignToMeValue) {
            params['assignedToMe'] = 1
        }else{
            params['assignedToMe'] = 0
        }

        return await getQaData(params);
    }

    useEffect(() => {
        let active = true;
        (async () => {
            try {
                setRowsState((prev) => ({ ...prev, loading: true }));
                const mediaFromServer = await fetchMediaData();
                if (mediaFromServer.status === 200) {
                    const { qa } = mediaFromServer.data;
                    const totalRows = +mediaFromServer.headers["x-total-count"];
                    setRowsState((prev) => (
                        {
                            ...prev,
                            rows: qa,
                            loading: false,
                            totalRows: totalRows,
                        }
                    )
                    );
                } else {
                    setRowsState((prev) => (
                        {
                            ...prev,
                            rows: [],
                            loading: false,
                            totalRows: 0,
                        }
                    )
                    );
                }


            }
            catch (e) {
                console.log(e);
                setRowsState((prev) => (
                    {
                        ...prev,
                        rows: [],
                        loading: false,
                        totalRows: 0,
                    }
                )
                );
            }

        })();

        return () => {
            active = false;
        };
    }, [
        rowsState.page,
        rowsState.pageSize,
        rowsState.companyUsage,
        rowsState.clientRequestHash,
        rowsState.qaStatus,
        isAdmin,
        projectValue,
        assignToMeValue,
    ]);

    const rowsOptionChange = (opt) => {
        setRowsState((prev) => ({
            ...prev,
            ...opt,
        })
        )
    }

    const statusOptionChange = (opt) => {
        setRowsState((prev) => ({
            ...prev,
            ...opt,
        })
        )
    }

    userProjects.sort((one, two) => (one.name > two.name) ? 1 : -1);
    const getProjectList = userProjects.map(project => {
        let projectList = { label: `${project.projectHash} - ${project.name}`, id: project.projectHash }
        return projectList
    })


    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <AppBar position="static" >
                <Toolbar
                    disableGutters={true}
                    sx={{
                        background: '#FFF',
                    }}
                >
                    <Search sx={{
                        marginRight: 0,
                    }}>
                        <StyledInputBase
                            id={'QADashboardSearch'}
                            placeholder="Search by session id…"
                            inputProps={{ 'aria-label': 'search' }}
                            onBlur={(e) => updateSearchText(e)}
                        />
                        <Button
                            color={'inherit'}
                            sx={{
                                backgroundColor: '#1DB8E0',
                                height: '40px',
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                '&:hover': {
                                    backgroundColor: '#02264D'
                                }
                            }}
                            onClick={activateSearch}
                        >
                            <SearchIcon />
                        </Button>
                    </Search>

                    {
                        !isAdmin && <Box sx={{ minWidth: 120 }}><MenuButton onChange={rowsOptionChange} /> </Box>
                    }

                    {
                        (isAdmin || (!isAdmin && rowsState.companyUsage === 2)) && <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            '> *': {
                                mx: 1,
                            },
                        }}>
                            <TableFilter
                                options={getProjectList}
                                inputValue={projectInputValue}
                                onChange={projectValueOnChange}
                                onInputChange={projectInputOnChange}/>
                            </Box>
                    }
                    <Box sx={{ minWidth: 120 }}><QaFilterMenu onChange={statusOptionChange} /> </Box>
                    <FormControlLabel
                        label="Assigned To Me"
                        labelPlacement="start"
                        sx={{
                            color: '#02264D'
                        }}
                        control={
                            <Switch
                                checked={assignToMeValue}
                                onChange={assignToMeOnChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                                />}>
                        </FormControlLabel>
                        {
                            cvatUser && <Box sx={{ marginLeft: 2 }}>
                            <Tooltip title={`${cvatUser.pendingTasks} : Pending Tasks`}>
                                <Typography sx={{ pl: .4,pr: .4,borderRadius: '50px', bgcolor: 'error.main',color:'white' }}>{cvatUser.pendingTasks}</Typography>
                                </Tooltip>
                            </Box>
                        }
                </Toolbar>
            </AppBar>
            <Paper
                sx={{
                    height: '100%',
                }}
                elevation={0}
            >
                <QaTable
                    onRowClick={onRowClick}
                    setTasks={setTasks}
                    rowsState={rowsState}
                    tableOptionChange={rowsOptionChange}
                    isAdmin={isAdmin}
                    onProjectColClick={onProjectColClick}
                    setProject={setProject}
                    isAnnotator={isAnnotator}
                    setMedia={setMedia}
                    onAssignClick={onAssignClick}
                />
            </Paper>
        </Card>
    )
}

export default VideoTableContainer;
