import * as React from "react";
import {useEffect, useState} from 'react';
import './App.css';
import QaDashboard from './components/QaDashboard'
import Header from './layout/Header'
import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import {getMeData} from './services/auth';
import Toolbar from "@mui/material/Toolbar";
import visuaTheme from './theme'
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey('25c7e7101ecebd5d21fb882ce02e263dTz02NDg1NCxFPTE3MTM1OTY5NDQxODgsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

function App() {
    const [userData, setUserData] = useState({
        developer: {
            email: '',
        },
        permission: [],
    });

    useEffect(() => {
        const params = {}
        params['product'] = 'qadashboard'
        const getUserData = async () => {
            const meData = await getMeData(params);
            return meData.data;
        }


        getUserData()
            .then(res => {
                const {data} = res;
                setUserData(data);
            })
            .catch(error => {
                if (!!error.response) {
                    const {status} = error.response;
                    if (status >= 400 || status < 500) {
                        window.location.replace("https://my.visua.com/backoffice/login");
                    } else {
                        console.error(error.response);
                    }
                } else {
                    console.error(error);
                }
            })
    }, [])

    return (
        <ThemeProvider theme={visuaTheme}>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <CssBaseline/>
                <Header userData={userData}/>
                <Toolbar/>

                {
                    !(userData.developer.email.length === 0) &&

                    <QaDashboard
                        userData={userData}
                        IannProjects={userData['iannProjects']}
                        canAccessVideo={userData.permission.includes("admin") || userData.permission.includes('apiCvat') | userData.permission.includes('amt')}
                        cvatUser={userData.cvatUser}
                        isAdmin={
                            userData.permission.includes("admin")
                            || userData.permission.includes("amt")
                        }
                        isAnnotator={
                            userData.permission.includes("amt")
                        }
                        isArealAdmin={
                            userData.permission.includes("admin")
                        }
                        userPermission={userData.permission}
                        userProjects={userData['cvatProjects']}
                    />
                }
            </Box>
        </ThemeProvider>
    )

};

export default App;
